(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name seasons.newTemporada.controller:NewTemporadaCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.seasons.newSeason')
  .controller('NewSeasonCtrl', NewSeasonCtrl);


  function NewSeasonCtrl($state, $filter, Seasons, $mdToast, $mdDialog) {
    var vm = this;
    vm.ctrlName = 'NewSeasonCtrl';
    vm.temporada = {};


    vm.confirmAlta = function (ev) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('ALERT'))
      .textContent($filter('translate')('NEW_SEASON_ALERT'))
      .targetEvent(ev)
      .ok('Si')
      .cancel('No');

      $mdDialog.show(confirm).then(function () {
        vm.addTemporada(vm.temporada);
      }, function () {
        //S'ha cancel·lat
      });

    };

    vm.addTemporada = function (temporada) {
      if (temporada.data2 <= temporada.data1 || temporada.data2 < moment().format('YYYY-MM-DD')) {
        $mdToast.show(
          $mdToast.simple()
          .textContent($filter('translate')('ERROR_INVALID_DATE'))
          .position('bottom left')
          .hideDelay(3000)
        );
      }
      else {
        var temporadaToSave = {
          name: temporada.name,
          iniDate: moment(temporada.data1).format('YYYY-MM-DD'),
          endDate: moment(temporada.data2).format('YYYY-MM-DD'),
          sportMatchDay: moment(temporada.datajornadaesportiva).format('YYYY-MM-DD')
        };
        Seasons.save(temporadaToSave, function () {
            $mdToast.show(
              $mdToast.simple()
              .textContent($filter('translate')('SUCCESS_NEW_SEASON'))
              .position('bottom left')
              .hideDelay(3000)
            );
            $state.go('home.seasons', {}, {reload: true});
          },
          function (error) {
            var message;
            if (error.status === 409) {
              message = 'ERROR_INVALID_DATE';
            } else {
              message = 'ERROR_BAD_REQUEST_DATE';
            }
            $mdToast.show(
              $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
            );
          });
      }
    };
  }
}());
